@import url("bootstrap.css");

.main-container {
  margin-top: 100px;
  max-width: 480px;
}

.login-form .submit {
  width: 100%;
}

.login-form .login-info {
  display: inline-flex;
  width: 100%;
}

.login-form .login-info .item {
  width: 50%;
}

.login-form .login-info .full-item {
  width: 100%;
  color: grey;
}

.fulfilled {
  color: green;
}
.noFulfilled {
  color: red;
}
